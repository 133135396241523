import React, { lazy, Suspense, useEffect } from 'react'

import { BrowserRouter, Routes as BrowserRoutes, Route } from 'react-router-dom'

import { useStorage, request } from './utils'

import {
    LandingPageLoader,
    GenericPageLoader,
    RequireAuthentication
} from './components'

const Home = lazy(() => {
    return Promise.all([
        import('./pages/home'),
        new Promise(resolve => setTimeout(resolve, 2000))
    ]).then(([moduleExports]) => moduleExports)
})

const PrivacyPolicy = lazy(() => import('./pages/privacyPolicy'))

const SignUp = lazy(() => import('./pages/signUp'))

const Verification = lazy(() => {
    return Promise.all([
        import('./pages/verification'),
        new Promise(resolve => setTimeout(resolve, 2000))
    ]).then(([moduleExports]) => moduleExports)
})

const SignIn = lazy(() => import('./pages/signIn'))

const Dashboard = lazy(() => import('./pages/dashboard'))

const NotFound = lazy(() => import('./pages/notFound'))

// If new pages are added or existing ones have it's path
// changed, update the site map and the structured data
// inside index on the public folder
const Routes = () => {
    // Authentication
    const [authenticationStorage, setAuthenticationStorage] = useStorage(
        process.env.REACT_APP_STORAGE_AUTHENTICATION
    )

    useEffect(() => {
        // Config token on request header
        request.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${authenticationStorage?.token}`

        // eslint-disable-next-line
    }, [authenticationStorage, setAuthenticationStorage])

    return (
        <BrowserRouter>
            <BrowserRoutes>
                <Route
                    path='/'
                    element={
                        <Suspense fallback={<LandingPageLoader />}>
                            <Home />
                        </Suspense>
                    }
                />

                <Route
                    path='/privacy-policy'
                    element={
                        <Suspense fallback={<></>}>
                            <PrivacyPolicy />
                        </Suspense>
                    }
                />

                <Route
                    path='/signup'
                    element={
                        <Suspense fallback={<></>}>
                            <SignUp />
                        </Suspense>
                    }
                />

                <Route
                    path='/verification'
                    element={
                        <Suspense fallback={<GenericPageLoader />}>
                            <Verification />
                        </Suspense>
                    }
                />

                <Route
                    path='/signin'
                    element={
                        <Suspense fallback={<></>}>
                            <SignIn />
                        </Suspense>
                    }
                />

                <Route element={<RequireAuthentication />}>
                    <Route
                        path='/dashboard'
                        element={
                            <Suspense fallback={<></>}>
                                <Dashboard />
                            </Suspense>
                        }
                    />
                </Route>

                <Route
                    path='*'
                    element={
                        <Suspense fallback={<></>}>
                            <NotFound />
                        </Suspense>
                    }
                />
            </BrowserRoutes>
        </BrowserRouter>
    )
}

export default Routes
