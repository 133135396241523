import React from 'react'

import { createRoot } from 'react-dom/client'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import reportWebVitals from './reportWebVitals'

import { CssBaseline, createTheme, ThemeProvider } from '@mui/material'

import { ToastContainer, Zoom } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import theme from './theme'

import Routes from './routes'

import './index.css'

// Don't use strict mode, it can duplicate request calls

// Application
const container = document.getElementById('root')

const root = createRoot(container)

root.render(
    // Changes made here needs to be replicated in the test file
    <>
        <CssBaseline />

        <ToastContainer
            position='bottom-center'
            transition={Zoom}
            autoClose={4000}
            closeButton={true}
            hideProgressBar={true}
            pauseOnHover={true}
            closeOnClick={true}
            newestOnTop={true}
            pauseOnFocusLoss={true}
            draggable={false}
            limit={4}
        />

        <ThemeProvider theme={createTheme(theme)}>
            <Routes />
        </ThemeProvider>
    </>
)

// Use unregister() or register() to register a service work to make
// the application work offline and load faster. Note this comes with
// some pitfalls
//
// Learn more: https://cra.link/PWA
serviceWorkerRegistration.register()

// Pass a function to log the results, e.g reportWebVitals(console.log),
// to  measuring the application performance
//
// Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
