import { useState, useEffect } from 'react'

import SecureLS from 'secure-ls'

const ls = new SecureLS({
    encodingType: 'aes',
    isCompression: true,
    encryptionSecret: process.env.REACT_APP_STORAGE_SECRET
})

const storageDefaultStates = {
    // Configured on routes, verification and sign in
    [process.env.REACT_APP_STORAGE_AUTHENTICATION]: {
        // JWT expiration at
        exp: 0,
        // JWA issued at
        iat: 0,
        // JWT subject
        sub: '',
        // JWT
        token: ''
    }
}

const useStorage = key => {
    const [state, setState] = useState(() => {
        try {
            return ls.get(key) || storageDefaultStates[key]
        } catch (error) {
            throw Error(error?.message)
        }
    })

    useEffect(() => {
        try {
            ls.set(key, state)
        } catch (error) {
            throw Error(error?.message)
        }
    }, [key, state])

    return [state, setState]
}

export { useStorage, storageDefaultStates }
