import React, { useEffect } from 'react'

import { Stack, useTheme, TextField, IconButton } from '@mui/material'

import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import * as Yup from 'yup'

import { toast } from 'react-toastify'

import { request, formatErrors, isObjectEmpty } from '../utils'

import { ReactComponent as Arrow } from '../assets/images/icons/arrow.svg'

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .required('Fill out this field')
        .matches(
            /^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$/,
            'Must contain at least four letters and up to two spaces between first, middle and last name'
        ),
    email: Yup.string()
        .required('Fill out this field')
        .trim()
        .email('Must be formatted as an email address'),
    text: Yup.string()
        .required('Fill out this field')
        .min(20, 'Must contain at least twenty characters')
        .max(400, 'Maximum number of characters reached')
})

const contactSubmit = parameters => async data => {
    const id = toast.loading('Sending message')

    try {
        await request.post('/contact/ticket', {
            ...data,
            source: parameters?.source ? parameters.source : 'application'
        })

        toast.update(id, {
            isLoading: false,
            render: 'Message sent',
            type: toast.TYPE.SUCCESS,
            autoClose: 4000
        })
    } catch (error) {
        toast.update(id, {
            isLoading: false,
            render: error?.response
                ? formatErrors(error.response.data.detail).next()?.value
                : error?.message,
            type: toast.TYPE.ERROR,
            autoClose: 4000
        })
    }
}

const Contact = ({ parameters }) => {
    const theme = useTheme()

    const {
        register,
        handleSubmit,
        watch,
        trigger,
        formState: { errors, isSubmitting }
    } = useForm({
        mode: 'onSubmit',
        shouldFocusError: false,
        resolver: yupResolver(ContactSchema),
        defaultValues: {
            name: parameters?.name ? parameters.name : '',
            email: parameters?.email ? parameters.email : '',
            text: ''
        }
    })

    useEffect(() => {
        if (!isObjectEmpty(parameters)) {
            trigger()
        }

        // eslint-disable-next-line
    }, [])

    return (
        <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            flexWrap='no-wrap'
            component='form'
            onSubmit={handleSubmit(contactSubmit(parameters))}
            sx={{ mb: '60px', gap: 8 }}
        >
            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                justifyContent='center'
                alignItems='flex-end'
                flexWrap='no-wrap'
                sx={{
                    width: '100%',
                    mb: '30px',
                    gap: { xs: 10, sm: 10, md: 4 }
                }}
            >
                <TextField
                    label='Name'
                    value={watch('name')}
                    variant='standard'
                    InputLabelProps={{
                        required: false,
                        shrink: false,
                        ...(watch('name') ? { sx: { display: 'none' } } : {})
                    }}
                    fullWidth
                    {...register('name')}
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    sx={{ flex: 1 }}
                />

                <TextField
                    label='Email'
                    value={watch('email')}
                    variant='standard'
                    InputLabelProps={{
                        required: false,
                        shrink: false,
                        ...(watch('email') ? { sx: { display: 'none' } } : {})
                    }}
                    fullWidth
                    {...register('email')}
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    sx={{
                        flex: 1
                    }}
                />
            </Stack>

            <Stack
                direction='row'
                justifyContent='center'
                alignItems='flex-end'
                flexWrap='no-wrap'
                spacing={4}
                sx={{ width: '100%', mb: '30px' }}
            >
                <TextField
                    label='Message'
                    value={watch('text')}
                    variant='standard'
                    InputLabelProps={{
                        required: false,
                        shrink: false,
                        ...(watch('text') ? { sx: { display: 'none' } } : {})
                    }}
                    fullWidth
                    multiline
                    {...register('text')}
                    error={!!errors.text}
                    helperText={errors?.text?.message}
                    sx={{ flex: 1 }}
                />

                <IconButton
                    color='primary'
                    component='button'
                    variant='text'
                    type='submit'
					aria-label='send-message'
                    disabled={isSubmitting}
                    sx={{
                        alignSelf: 'flex-start',
                        height: '55px',
                        padding: 0,
                        borderRadius: 0,
                        background: 'transparent',
                        '&:hover': {
                            background: 'transparent',
                            '& svg': theme.effect.hover
                        }
                    }}
                >
                    <Arrow width='34' height='34' />
                </IconButton>
            </Stack>
        </Stack>
    )
}

export default Contact
