import axios from 'axios'

// Learn more: https://axios-http.com/docs/instance
const request = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000
})

request.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (!error.response) {
            // The server is taking to long to answer, thus canceling based
            // on the timeout, or the user has no internet connection.
            return axios.request(error.config)
        }

        return Promise.reject(error)
    }
)

export default request
