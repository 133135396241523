import React from 'react'

import { Typography } from '@mui/material'

const SubTitle = ({ variant, component, sx, text }) => {
    return (
        <Typography
            variant={variant ? variant : 'body1'}
            component={component ? component : 'p'}
            sx={{
                fontSize: '1.25rem',
                fontWeight: 400,
                ...sx
            }}
        >
            {text}
        </Typography>
    )
}

export default SubTitle
