import React from 'react'

import { Stack, useTheme, Typography, Link } from '@mui/material'

const FooterExternal = () => {
    const theme = useTheme()

    return (
        <Stack
            direction='row'
            justifyContent='space-between'
            flexWrap='wrap'
            alignItems={{ xs: 'flex-start', sm: 'center', md: 'center' }}
            sx={{
                minHeight: { xs: '64px', sm: '100px' },
                gap: { xs: 0, sm: 4 },
                mt: 8
            }}
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='flex-end'
                spacing={{ xs: 3, sm: 4 }}
            >
                <Link href='/privacy-policy' target='_self' rel='noreferrer'>
                    <Typography
                        variant='body2'
                        sx={{ color: theme.palette.primary.main }}
                    >
                        Privacy Policy
                    </Typography>
                </Link>
            </Stack>
        </Stack>
    )
}

export default FooterExternal
