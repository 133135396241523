import React from 'react'

import { Box, useTheme } from '@mui/material'

const Loader = ({ sx, sxDots }) => {
    const theme = useTheme()

    return (
        <Box
            sx={{
                display: 'inline-block',
                position: 'relative',
                width: '80px',
                height: '80px',
                ...sx,
                '& div': {
                    position: 'absolute',
                    top: '33px',
                    width: '13px',
                    height: '13px',
                    borderRadius: '50%',
                    background: theme.palette.primary.main,
                    animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
                    ...sxDots
                },
                '& div:nth-child(1)': {
                    left: '8px',
                    animation: 'ellipsis1 0.6s infinite'
                },
                '& div:nth-child(2)': {
                    left: '8px',
                    animation: 'ellipsis2 0.6s infinite'
                },
                '& div:nth-child(3)': {
                    left: '32px',
                    animation: 'ellipsis2 0.6s infinite'
                },
                '& div:nth-child(4)': {
                    left: '56px',
                    animation: 'ellipsis3 0.6s infinite'
                },
                '@keyframes ellipsis1': {
                    from: {
                        transform: 'scale(0)'
                    },
                    to: {
                        transform: 'scale(1)'
                    }
                },
                '@keyframes ellipsis3': {
                    from: {
                        transform: 'scale(1)'
                    },
                    to: {
                        transform: 'scale(0)'
                    }
                },
                '@keyframes ellipsis2': {
                    from: {
                        transform: 'translate(0, 0)'
                    },
                    to: {
                        transform: 'translate(24px, 0)'
                    }
                }
            }}
        >
            <Box></Box>

            <Box></Box>

            <Box></Box>

            <Box></Box>
        </Box>
    )
}

export default Loader
