const languages = [
    { code: 'af', country: 'Afghanistan', emoji: '🇦🇫', language: 'Afrikaans' },
    { code: 'sq', country: 'Albania', emoji: '🇦🇱', language: 'Albanian' },
    { code: 'am', country: 'Armenia', emoji: '🇦🇲', language: 'Amharic' },
    { code: 'hy', country: 'Armenia', emoji: '🇦🇲', language: 'Armenian' },
    { code: 'az', country: 'Azerbaijan', emoji: '🇦🇿', language: 'Azerbaijani' },
    { code: 'eu', country: 'Basque', emoji: '🏴󠁥󠁳󠁰󠁶󠁿', language: 'Basque' },
    { code: 'be', country: 'Belgium', emoji: '🇧🇪', language: 'Belarusian' },
    { code: 'bn', country: 'Brunei Darussalam', emoji: '🇧🇳', language: 'Bengali' },
    { code: 'bs', country: 'Bahamas', emoji: '🇧🇸', language: 'Bosnian' },
    { code: 'bg', country: 'Bulgaria', emoji: '🇧🇬', language: 'Bulgarian' },
    { code: 'ca', country: 'Canada', emoji: '🇨🇦', language: 'Catalan' },
    { code: 'ceb', country: 'Cebuano', emoji: '🏴󠁥󠁳󠁰󠁶󠁿', language: 'Cebuano' },
    { code: 'zh-CN', country: 'China', emoji: '🇨🇳', language: 'Chinese (Simplified)' },
    { code: 'zh-TW', country: 'China', emoji: '🇨🇳', language: 'Chinese (Traditional)' },
    { code: 'co', country: 'Colombia', emoji: '🇨🇴', language: 'Corsican' },
    { code: 'hr', country: 'Croatia', emoji: '🇭🇷', language: 'Croatian' },
    { code: 'cs', country: 'Czech', emoji: '🇨🇿', language: 'Czech' },
    { code: 'da', country: 'Denmark', emoji: '🇩🇰', language: 'Danish' },
    { code: 'nl', country: 'Netherlands', emoji: '🇳🇱', language: 'Dutch' },
    { code: 'en', country: 'United States', emoji: '🇺🇸', language: 'English' },
    { code: 'et', country: 'Ethiopia', emoji: '🇪🇹', language: 'Estonian' },
    { code: 'fi', country: 'Finland', emoji: '🇫🇮', language: 'Finnish' },
    { code: 'fr', country: 'France', emoji: '🇫🇷', language: 'French' },
    { code: 'fy', country: 'Friesland', emoji: '🏴󠁥󠁳󠁰󠁶󠁿', language: 'Frisian' },
    { code: 'gl', country: 'Greenland', emoji: '🇬🇱', language: 'Galician' },
    { code: 'ka', country: 'Georgia', emoji: '🇬🇪', language: 'Georgian' },
    { code: 'de', country: 'Germany', emoji: '🇩🇪', language: 'German' },
    { code: 'el', country: 'Greece', emoji: '🇬🇷', language: 'Greek' },
    { code: 'gu', country: 'Guam', emoji: '🇬🇺', language: 'Gujarati' },
    { code: 'ht', country: 'Haiti', emoji: '🇭🇹', language: 'Haitian Creole' },
    { code: 'hi', country: 'India', emoji: '🇮🇳', language: 'Hindi' },
    { code: 'hu', country: 'Hungary', emoji: '🇭🇺', language: 'Hungarian' },
    { code: 'is', country: 'Iceland', emoji: '🇮🇸', language: 'Icelandic' },
    { code: 'id', country: 'Indonesia', emoji: '🇮🇩', language: 'Indonesian' },
    { code: 'ga', country: 'Gabon', emoji: '🇬🇦', language: 'Irish' },
    { code: 'it', country: 'Italy', emoji: '🇮🇹', language: 'Italian' },
    { code: 'ja', country: 'Japan', emoji: '🇯🇵', language: 'Japanese' },
    { code: 'jv', country: 'Indonesia', emoji: '🇮🇩', language: 'Javanese' },
    { code: 'kn', country: 'Saint Kitts and Nevis', emoji: '🇰🇳', language: 'Kannada' },
    { code: 'km', country: 'Comoros', emoji: '🇰🇲', language: 'Khmer' },
    { code: 'rw', country: 'Rwanda', emoji: '🇷🇼', language: 'Kinyarwanda' },
    { code: 'ko', country: 'South Korea', emoji: '🇰🇷', language: 'Korean' },
    { code: 'ky', country: 'Cayman Islands', emoji: '🇰🇾', language: 'Kyrgyz' },
    { code: 'la', country: "Lao People's Democratic Republic", emoji: '🇱🇦', language: 'Latin' },
    { code: 'lv', country: 'Latvia', emoji: '🇱🇻', language: 'Latvian' },
    { code: 'lt', country: 'Lithuania', emoji: '🇱🇹', language: 'Lithuanian' },
    { code: 'lb', country: 'Lebanon', emoji: '🇱🇧', language: 'Luxembourgish' },
    { code: 'mk', country: 'Macedonia', emoji: '🇲🇰', language: 'Macedonian' },
    { code: 'mg', country: 'Madagascar', emoji: '🇲🇬', language: 'Malagasy' },
    { code: 'ms', country: 'Montserrat', emoji: '🇲🇸', language: 'Malay' },
    { code: 'ml', country: 'Mali', emoji: '🇲🇱', language: 'Malayalam' },
    { code: 'mt', country: 'Malta', emoji: '🇲🇹', language: 'Maltese' },
    { code: 'mr', country: 'Mauritania', emoji: '🇲🇷', language: 'Marathi' },
    { code: 'mn', country: 'Mongolia', emoji: '🇲🇳', language: 'Mongolian' },
    { code: 'my', country: 'Malaysia', emoji: '🇲🇾', language: 'Myanmar (Burmese)' },
    { code: 'ne', country: 'Niger', emoji: '🇳🇪', language: 'Nepali' },
    { code: 'no', country: 'Norway', emoji: '🇳🇴', language: 'Norwegian' },
    { code: 'ps', country: 'Palestinian Territory', emoji: '🇵🇸', language: 'Pashto' },
    { code: 'pl', country: 'Poland', emoji: '🇵🇱', language: 'Polish' },
    { code: 'pt', country: 'Portugal', emoji: '🇵🇹', language: 'Portuguese (Portugal, Brazil)' },
    { code: 'pa', country: 'Panama', emoji: '🇵🇦', language: 'Punjabi' },
    { code: 'ro', country: 'Romania', emoji: '🇷🇴', language: 'Romanian' },
    { code: 'ru', country: 'Russia', emoji: '🇷🇺', language: 'Russian' },
    { code: 'sm', country: 'San Marino', emoji: '🇸🇲', language: 'Samoan' },
    { code: 'gd', country: 'Grenada', emoji: '🇬🇩', language: 'Scots Gaelic' },
    { code: 'sr', country: 'Suriname', emoji: '🇸🇷', language: 'Serbian' },
    { code: 'st', country: 'Sao Tome and Principe', emoji: '🇸🇹', language: 'Sesotho' },
    { code: 'sn', country: 'Senegal', emoji: '🇸🇳', language: 'Shona' },
    { code: 'sd', country: 'Sudan', emoji: '🇸🇩', language: 'Sindhi' },
    { code: 'si', country: 'Slovenia', emoji: '🇸🇮', language: 'Sinhala (Sinhalese)' },
    { code: 'sk', country: 'Slovakia', emoji: '🇸🇰', language: 'Slovak' },
    { code: 'sl', country: 'Sierra Leone', emoji: '🇸🇱', language: 'Slovenian' },
    { code: 'so', country: 'Somalia', emoji: '🇸🇴', language: 'Somali' },
    { code: 'es', country: 'Spain', emoji: '🇪🇸', language: 'Spanish' },
    { code: 'sv', country: 'El Salvador', emoji: '🇸🇻', language: 'Swedish' },
    { code: 'tl', country: 'Timor-Leste', emoji: '🇹🇱', language: 'Tagalog (Filipino)' },
    { code: 'tg', country: 'Togo', emoji: '🇹🇬', language: 'Tajik' },
    { code: 'tt', country: 'Trinidad and Tobago', emoji: '🇹🇹', language: 'Tatar' },
    { code: 'te', country: 'India', emoji: '🇮🇳', language: 'Telugu' },
    { code: 'th', country: 'Thailand', emoji: '🇹🇭', language: 'Thai' },
    { code: 'tr', country: 'Turkey', emoji: '🇹🇷', language: 'Turkish' },
    { code: 'tk', country: 'Tokelau', emoji: '🇹🇰', language: 'Turkmen' },
    { code: 'uk', country: 'Ukraine', emoji: '🇺🇦', language: 'Ukrainian' },
    { code: 'ug', country: 'Uganda', emoji: '🇺🇬', language: 'Uyghur' },
    { code: 'uz', country: 'Uzbekistan', emoji: '🇺🇿', language: 'Uzbek' },
    { code: 'vi', country: 'Virgin Islands, U.S.', emoji: '🇻🇮', language: 'Vietnamese' },
    { code: 'cy', country: 'Cyprus', emoji: '🇨🇾', language: 'Welsh' },
]

export default languages
