const theme = {
    // Theme
    //
    // Learn more: https://mui.com/customization/default-theme
    //
    // Learn more: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
    palette: {
        common: {
            white: '#ffffff',
            black: '#000000',
            gray: '#EAEAEA'
        },
        primary: {
            light: '#3a3d43',
            main: '#090d15',
            dark: '#06090e',
            contrastText: '#ffffff'
        },
        secondary: {
            light: '#b3a2f3',
            main: '#ab98f2',
            dark: '#9988d9',
            contrastText: '#ab98f2'
        },
        tertiary: {
            main: '#f2f7fb'
        },
        text: {
            primary: '#090d15',
            secondary: 'rgba(9, 13, 21, .50)',
            disabled: 'rgba(174, 176, 180, 1)'
        },
        error: {
            main: '#ab98f2'
        }
    },
    shape: {
        borderRadius: 1.5
    },
    typography: {
        h1: {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 500,
            fontSize: '4.5rem',
            color: '#090d15',
            letterSpacing: 0
        },
        h2: {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
            fontSize: '1.35rem',
            color: '#090d15',
            letterSpacing: 0
        },
        h3: {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
            fontSize: '1.25rem',
            color: '#090d15',
            letterSpacing: 0
        },
        h4: {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
            fontSize: '1.25rem',
            color: '#090d15',
            letterSpacing: 0
        },
        h5: {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
            fontSize: '1.15rem',
            color: '#090d15',
            letterSpacing: 0
        },
        h6: {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
            fontSize: '1.10rem',
            color: '#090d15',
            letterSpacing: 0
        },
        subtitle1: {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
            fontSize: '1.25rem',
            color: '#090d15',
            letterSpacing: 0
        },
        subtitle2: {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
            fontSize: '.75rem',
            color: '#090d15',
            letterSpacing: 0
        },
        body1: {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
            fontSize: '1rem',
            color: '#090d15',
            letterSpacing: 0
        },
        body2: {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 300,
            fontSize: '1rem',
            color: '#090d15',
            letterSpacing: 0
        },
        button: {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 500,
            fontSize: '1rem',
            textTransform: 'none',
            letterSpacing: 0
        }
    },
    // Project property
    effect: {
        hover: {
            transition: 'transform 200ms ease-in-out',
            transform: 'scale(1.1)'
        },
        shadows: {
            button: {
                // prettier-ignore
                boxShadow: '0px 3px 1px -2px rgb(9 13 21 / 20%), 0px 2px 2px 0px rgb(9 13 21 / 14%), 0px 1px 5px 0px rgb(9 13 21 / 12%)'
            },
            box: {
                boxShadow: '0px 0px 15px rgb(179 179 179 / 5%)'
            }
        }
    },
    // Components
    //
    // Learn more: https://mui.com/customization/theme-components
    components: {
        // Link
        MuiLink: {
            defaultProps: {
                underline: 'none'
            },
            styleOverrides: {
                root: {
                    '&:hover': {
                        transition: 'transform 200ms ease-in-out',
                        transform: 'scale(1.1)'
                    }
                }
            }
        },
        // Button
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            }
        },
        MuiButton: {
            defaultProps: {
                color: 'primary',
                disableElevation: true,
                disableFocusRipple: true,
                disableRipple: true,
                variant: 'contained'
            },
            styleOverrides: {
                root: {
                    minWidth: 128,
                    minHeight: 43,
                    fontSize: '1.05rem',
                    borderRadius: '2.25px',
                    // prettier-ignore
                    boxShadow: '0px 3px 1px -2px rgb(9 13 21 / 20%), 0px 2px 2px 0px rgb(9 13 21 / 14%), 0px 1px 5px 0px rgb(9 13 21 / 12%)',
                    '&:hover': {
                        // prettier-ignore
                        boxShadow: '0px 3px 1px -2px rgb(9 13 21 / 20%), 0px 2px 2px 0px rgb(9 13 21 / 14%), 0px 1px 5px 0px rgb(9 13 21 / 12%)'
                    },
                    '&:disabled': {
                        background: '#090d15',
                        color: '#ffffff'
                    }
                }
            }
        },
        MuiIconButton: {
            defaultProps: {
                disableFocusRipple: true,
                disableRipple: true
            }
        },
        // Typography
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'highlight' },
                    style: {
                        color: '#ab98f2'
                    }
                },
                {
                    props: { variant: 'contrast' },
                    style: {
                        color: '#ffffff'
                    }
                },
                {
                    props: { variant: 'abstract' },
                    style: {
                        color: '#AEB0B4'
                    }
                }
            ]
        },
        // List
        MuiListItemButton: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: 'transparent'
                    },
                    '&.Mui-selected': {
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent'
                        },
                        '&.Mui-focusVisible': {}
                    }
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    color: '#090d15',
                    fontSize: '1rem',
                    fontWeight: 400
                }
            }
        },
        // App Bar
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        // Text Field
        MuiInput: {
            styleOverrides: {
                root: {
                    paddingBottom: '4px',
                    fontSize: '1.25rem',
                    '&:before': {
                        borderBottom: '2.35px solid rgba(174, 176, 180, .15)'
                    },
                    '&:not(.Mui-disabled):hover::before': {
                        borderColor: 'rgba(174, 176, 180, .30)'
                    },
                    '&:after': {
                        borderColor: 'rgba(9, 13, 21, .75)'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem',
                    color: '#090d15',
                    top: '-4px',
                    // The focused config requires that the Text Field implements
                    //
                    // InputLabelProps={{
                    //     shrink: false,
                    //     ...(fieldValue ? { sx: { display: 'none' } } : {})
                    // }}
                    '&.Mui-focused': {
                        display: 'none'
                    },
                    '&.Mui-error': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    bottom: '-40px',
                    left: 0,
                    minHeight: '32px',
                    fontSize: '.65rem',
                    fontWeight: 300,
                    color: 'rgba(9, 13, 21, .75)'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: '#090d15'
                }
            }
        },
        // Dialog
        MuiDialog: {
            styleOverrides: {
                paper: {
                    boxShadow: 'none'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    '& button': {
                        fontSize: '.85rem',
                        minWidth: 96,
                        minHeight: 40
                    }
                }
            }
        },
        // Drawer
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#ffffff',
                    boxShadow: 'none'
                },
                modal: {}
            }
        },
        // Divider
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: 'rgba(9, 13, 21, 0.05)'
                }
            }
        },
        // Stepper
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 300
                },
                label: {
                    '&.Mui-active': {
                        fontWeight: 300
                    }
                }
            }
        },
        // Alert
        MuiAlert: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                },
                action: {
                    alignItems: 'center',
                    '& button': {
                        backgroundColor: 'inherit',
                        fontSize: '.85rem',
                        fontWeight: '400',
                        minHeight: 35,
                        minWidth: 90,
                        '&:hover': {
                            backgroundColor: 'inherit'
                        }
                    }
                }
            }
        },
        // Snack Bar
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    borderRadius: '2.25px',
                    backgroundColor: 'transparent',
                    '& div.MuiAlert-standard': {
                        // prettier-ignore
                        boxShadow: '0 0 10px 0 rgb(9 13 21 / 5%), 0 0 15px 0 rgb(9 13 21 / 5%)',
                        alignItems: 'center'
                    },
                    '& div.MuiAlert-standard button': {
                        lineHeight: 0,
                        minWidth: 'inherit',
                        minHeight: 'inherit',
                        backgroundColor: 'inherit',
                        boxShadow: 'none'
                    },
                    '& div.MuiAlert-standard div.MuiAlert-message': {
                        lineHeight: 0
                    }
                }
            }
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    borderRadius: '2.25px',
                    // prettier-ignore
                    boxShadow: 'rgba(174, 176, 180, .05) 0px 0px 5px -1px, rgba(174, 176, 180, .05) 0px 0px 5px -5px'
                }
            }
        }
    }
}

export default theme
