import React from 'react'

import { Box, Typography } from '@mui/material'

const LandingPageLoader = () => {
    return (
        <Box
            sx={{
                overflow: 'hidden',
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px',
                boxSizing: 'border-box',
                '@keyframes fadeOut': {
                    from: {
                        opacity: 1
                    },
                    to: {
                        opacity: 0
                    }
                },
                animationFillMode: 'forwards',
                animationName: 'fadeOut',
                animationDuration: '1.75s',
                animationDelay: '1.35s'
            }}
        >
            <Typography
                variant='h1'
                component='span'
                sx={{
                    position: 'relative',
                    '@keyframes fadeIn': {
                        from: {
                            opacity: 0,
                            bottom: '-25px'
                        },
                        to: {
                            opacity: 1,
                            bottom: '0px'
                        }
                    },
                    animationFillMode: 'forwards',
                    animationName: 'fadeIn',
                    animationDuration: '1.15s',
                    fontSize: '2rem',
                    textAlign: 'center'
                }}
            >
                Translating...
            </Typography>
        </Box>
    )
}

export default LandingPageLoader
