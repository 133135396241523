import React, { useState, useEffect } from 'react'

import { Container, Box, useTheme, useMediaQuery } from '@mui/material'

import {
    Loader,
    Title,
    SubTitle,
    ThemeDrawer,
    ExpandDrawer,
    DownloadDrawer,
    CloseDrawer
} from './'

import { request, formatErrors } from '../utils'

const translateSubmit = async ({ image, language }) => {
    const formData = new FormData()

    formData.append('images', image.data)

    formData.append('language', language.code)

    try {
        const response = await request.post('/image/translate', formData)

        return response.data
    } catch (error) {
        throw Error(
            error?.response
                ? formatErrors(error.response.data.detail).next()?.value
                : error?.message
        )
    }
}

const Gallery = ({ data, themeDrawer, setThemeDrawer }) => {
    const theme = useTheme()

    const [images, setImages] = useState([])

    useEffect(() => {
        ;(async () => {
            for (const image of data?.current?.files?.successful) {
                const imageBase64 = await translateSubmit({
                    image,
                    language: data.current.language
                })

                setImages(previous => [...previous, ...imageBase64.images])
            }
        })()

        // eslint-disable-next-line
    }, [])

    return (
        <>
            {images?.length ? (
                <Box
                    sx={{
                        width: '100%',
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    {images.map((image, index) => (
                        <img
                            src={`data:image/png;base64, ${image}`}
                            alt={index}
                            key={`Image ${index}`}
                            style={{ width: '100%' }}
                        />
                    ))}
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '525px',
                        maxHeight: '50vh',
                        width: '100%',
                        overflowX: 'scroll'
                    }}
                >
                    <Loader
                        sxDots={
                            themeDrawer
                                ? { background: theme.palette.common.white }
                                : {}
                        }
                    />
                </Box>
            )}
        </>
    )
}

const Submit = ({
    open,
    setOpen,
    data,
    themeDrawer,
    setThemeDrawer,
    expandDrawer,
    setExpandDrawer
}) => {
    const theme = useTheme()

    const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Box
            sx={{
                width: expandDrawer ? '100vw' : '100%',
                height: '100%'
            }}
        >
            <Container
                maxWidth={expandDrawer ? 'md' : 'xs'}
                sx={{
                    height: expandDrawer ? '100vh' : '96vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                    // Don't `justifyContent' center otherwise the content
                    // don't scroll
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        marginTop: 14,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Title
                        text='Gallery'
                        sx={{
                            fontSize: { xs: '2.50rem', sm: '3rem', md: '3rem' },
                            width: '100%',
                            ...(themeDrawer
                                ? { color: theme.palette.common.white }
                                : {})
                        }}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            gap: 2
                        }}
                    >
                        <ThemeDrawer
                            themeDrawer={themeDrawer}
                            setThemeDrawer={setThemeDrawer}
                            sx={
                                themeDrawer
                                    ? { stroke: theme.palette.common.white }
                                    : {}
                            }
                        />

                        <ExpandDrawer
                            expandDrawer={expandDrawer}
                            setExpandDrawer={setExpandDrawer}
                            sx={
                                themeDrawer
                                    ? { stroke: theme.palette.common.white }
                                    : {}
                            }
                        />

                        <DownloadDrawer
                            sx={
                                themeDrawer
                                    ? { stroke: theme.palette.common.white }
                                    : {}
                            }
                        />

                        {(matchesDownMd || expandDrawer) && (
                            <CloseDrawer
                                open={open}
                                setOpen={setOpen}
                                sx={
                                    themeDrawer
                                        ? { stroke: theme.palette.common.white }
                                        : {}
                                }
                            />
                        )}
                    </Box>
                </Box>

                <SubTitle
                    text="For now we don't store any file (s), but the download option is available above"
                    sx={{
                        mt: 4,
                        mb: 4,
                        ...(themeDrawer
                            ? { color: theme.palette.common.white }
                            : {})
                    }}
                />

                <Gallery
                    data={data}
                    themeDrawer={themeDrawer}
                    setThemeDrawer={setThemeDrawer}
                />
            </Container>
        </Box>
    )
}

export default Submit
