import React from 'react'

import { Drawer, useTheme, useMediaQuery } from '@mui/material'

const toggleDrawer =
    ({ open, setOpen }) =>
    event => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }

        setOpen(!open)
    }

const ContentDrawer = ({ open, setOpen, children, sx }) => {
    const theme = useTheme()

    const matchesUpLg = useMediaQuery(theme.breakpoints.up('lg'))

    const matchesDownLg = useMediaQuery(theme.breakpoints.down('lg'))

    return (
        <Drawer
            anchor={matchesUpLg ? 'right' : 'bottom'}
            open={open}
            onClose={toggleDrawer({ open, setOpen })}
            PaperProps={{
                sx: {
                    ...(matchesUpLg
                        ? {
                              minWidth: matchesDownLg ? '65vw' : '50vw',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center'
                          }
                        : {
                              minHeight: 'fit-content',
                              maxHeight: 'fit-content',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderTopRightRadius: 5,
                              borderTopLeftRadius: 5
                          }),
                    ...sx
                }
            }}
        >
            {children}
        </Drawer>
    )
}

export default ContentDrawer
