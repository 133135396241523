import React, { useState, useEffect } from 'react'

import {
    Container,
    Box,
    Chip,
    Avatar,
    useTheme,
    useMediaQuery,
    Typography
} from '@mui/material'

import { Title, SubTitle, CloseDrawer } from './'

import languages from '../assets/data/languages'

const SelectLanguageForm = ({ data }) => {
    const theme = useTheme()

    const [language, setLanguage] = useState(data.current?.language)

    useEffect(() => {
        data.current.language = language

        // eslint-disable-next-line
    }, [language, setLanguage])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                flexWrap: 'wrap',
                p: 2,
                pb: 0
            }}
        >
            <Typography component='pre' sx={{ mb: 2, fontSize: '.85rem' }}>
                {language.emoji} {language.language}
            </Typography>

            <Box
                sx={{
                    height: '525px',
                    maxHeight: '50vh',
                    overflowX: 'scroll',
                    pt: 2,
                    border: `1px dashed ${theme.palette.common.gray}`,
                    '& > *:last-child': {
                        mb: 0
                    }
                }}
            >
                {languages
                    .filter(
                        _language => _language.language !== language.language
                    )
                    .map(_language => (
                        <Chip
                            avatar={
                                <Avatar sx={{ background: 'none' }}>
                                    {_language?.emoji}
                                </Avatar>
                            }
                            label={_language.language}
                            key={_language.code}
                            onClick={() => {
                                setLanguage(_language)
                            }}
                            sx={{
                                mb: 2,
                                background: 'none',
                                width: '100%',
                                justifyContent: 'flex-start',
                                '&:hover': {
                                    boxShadow: 'none',
                                    background: 'transparent'
                                },
                                ...(_language?.code === language?.code
                                    ? {
                                          // Remove hover border and shadow
                                          background: theme.palette.common.gray
                                      }
                                    : {})
                            }}
                        />
                    ))}
            </Box>
        </Box>
    )
}

const SelectLanguage = ({ open, setOpen, data }) => {
    const theme = useTheme()

    const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Container
            maxWidth='xs'
            sx={{
                minHeight: '96vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Title
                    text='Select language'
                    sx={{ fontSize: { xs: '2.50rem', sm: '3rem', md: '3rem' } }}
                />

                {matchesDownMd && <CloseDrawer open={open} setOpen={setOpen} />}
            </Box>

            <SubTitle
                text='Select the language you want to translate the image (s) to'
                sx={{ mt: 4, mb: 4 }}
            />

            <SelectLanguageForm data={data} />
        </Container>
    )
}

export default SelectLanguage
