import React, { useState } from 'react'

import { Stack, Box, Typography, useTheme } from '@mui/material'

import {
    Title,
    ContentDrawer,
    SelectImage,
    SelectLanguage,
    Submit
} from './'

import { ReactComponent as Load } from '../assets/images/icons/load.svg'

import { ReactComponent as Geography } from '../assets/images/icons/geography.svg'

import { ReactComponent as Crystall } from '../assets/images/icons/crystall.svg'

const TranslateCard = ({
    title,
    description,
    icon: Icon,
    component: Component,
    states,
    sx,
    data
}) => {
    const [open, setOpen] = useState(false)

    const theme = useTheme()

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    flex: 1,
                    gap: 3,
                    width: '100%',
                    minHeight: ['240px', '220px', '320px', '240px'],
                    maxHeight: 'fit-content',
                    p: 4,
                    boxSizing: 'border-box',
                    cursor: 'pointer',
                    background: sx.backgroundColor,
                    borderRadius: theme.shape.borderRadius,
                    ...theme.effect.shadows.box
                }}
                onClick={() => {
                    setOpen(true)
                }}
            >
                <Icon
                    style={{
                        width: '34px',
                        height: '34px',
                        minWidth: '28px',
                        minHeight: '28px',
                        fill: sx.color
                    }}
                />

                <Typography
                    variant='h1'
                    component='h1'
                    gutterBottom
                    sx={{
                        maxWidth: ['unset', 'unset', '24ch'],
                        fontSize: ['1.80rem', '2rem'],
                        color: sx.color,
                        mb: 0
                    }}
                >
                    {title}
                </Typography>

                <Typography
                    variant='h2'
                    component='h2'
                    gutterBottom
                    sx={{
                        maxWidth: ['unset', 'unset', '42ch'],
                        fontSize: '1.05rem',
                        color: sx.color
                    }}
                >
                    {description}
                </Typography>
            </Box>

            <ContentDrawer
                open={open}
                setOpen={setOpen}
                sx={sx.drawer}
                children={
                    <Component
                        open={open}
                        setOpen={setOpen}
                        data={data}
                        {...states}
                    />
                }
            />
        </>
    )
}

const Translate = ({ data }) => {
    const theme = useTheme()

    const [themeDrawer, setThemeDrawer] = useState(false)

    const [expandDrawer, setExpandDrawer] = useState(false)

    return (
        <Stack
            direction='column'
            flexWrap='wrap'
            alignItems='flex-start'
            justifyContent='start'
            sx={{
                width: '100%',
                mt: 8
            }}
        >
            <Stack
                direction='row'
                flexWrap='wrap'
                alignItems='flex-start'
                justifyContent='space-between'
                sx={{
                    width: '100%'
                }}
            >
                <Box>
                    <Title text='Translate' sx={{ fontSize: '3rem' }} />
                </Box>
            </Stack>

            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                flexWrap='wrap'
                alignItems={['center', 'center', 'flex-start', 'center']}
                justifyContent='space-between'
                sx={{
                    gap: 8,
                    width: '100%',
                    mt: 7
                }}
            >
                <TranslateCard
                    title='Select image (s)'
                    description='Select the image (s) you want to translate in a valid format like PNG, JPG, etc'
                    icon={Load}
                    component={SelectImage}
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.common.white
                    }}
                    data={data}
                />

                <TranslateCard
                    title='Select language'
                    description='Select the language you want to translate the image (s) text to'
                    icon={Geography}
                    component={SelectLanguage}
                    sx={{
                        backgroundColor: theme.palette.tertiary.main,
                        color: theme.palette.primary.main
                    }}
                    data={data}
                />

                <TranslateCard
                    title='Submit'
                    description='Translate the image (s) to the selected language, don’t close the browser'
                    icon={Crystall}
                    component={Submit}
                    states={{
                        themeDrawer,
                        setThemeDrawer,
                        expandDrawer,
                        setExpandDrawer
                    }}
                    sx={{
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.common.white,
                        drawer: {
                            overflow: 'scroll',
                            justifyContent: 'flex-end',
                            ...(expandDrawer
                                ? {
                                      height: '100vh',
                                      borderTopRightRadius: 0,
                                      borderTopLeftRadius: 0
                                  }
                                : {}),
                            ...(themeDrawer
                                ? { background: theme.palette.primary.main }
                                : {})
                        }
                    }}
                    data={data}
                />
            </Stack>
        </Stack>
    )
}

export default Translate
