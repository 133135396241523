import React from 'react'

import { Link } from '@mui/material'

import LogoDark from '../assets/images/brand/logoDark.svg'

const Logo = () => {
    return (
        <Link
            href='/'
            rel='noreferrer'
            target='_self'
            sx={{ '&:hover': { transform: 'unset' } }}
        >
            <img
                src={LogoDark}
                alt='Traduz'
                width='100'
                height='25'
                loading='lazy'
            />
        </Link>
    )
}

export default Logo
