import React from 'react'

import { Container, Box, useTheme, useMediaQuery } from '@mui/material'

import Uppy from '@uppy/core'

import { Dashboard } from '@uppy/react'

import DropTarget from '@uppy/drop-target'

import Compressor from '@uppy/compressor'

import '@uppy/core/dist/style.css'

import '@uppy/dashboard/dist/style.css'

import '@uppy/drop-target/dist/style.css'

import { Title, SubTitle, CloseDrawer } from './'

const SelectImageForm = ({ data }) => {
    const uppy = new Uppy({
        id: 'uppy',
        autoProceed: true,
        restrictions: {
            // maxFileSize: 10mb
            maxFileSize: 10485760,
            // minFileSize: null,
            // maxTotalFileSize: 100mb
            maxTotalFileSize: 104857600,
            maxNumberOfFiles: 1000,
            // minNumberOfFiles: null,
            allowedFileTypes: ['.jpg', '.jpeg', '.png']
        }
    })
        .use(DropTarget, { id: 'DropTarget', target: document.body })
        .use(Compressor, { id: 'Compressor' })

    uppy.on('complete', files => {
        data.current.files = files
    })

    return (
        <Dashboard
            uppy={uppy}
            disableStatusBar={true}
            proudlyDisplayPoweredByUppy={false}
            theme='light'
        />
    )
}

const SelectImage = ({ open, setOpen, data }) => {
    const theme = useTheme()

    const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Container
            maxWidth='xs'
            sx={{
                minHeight: '96vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Title
                    text='Select image (s)'
                    sx={{ fontSize: { xs: '2.50rem', sm: '3rem', md: '3rem' } }}
                />

                {matchesDownMd && <CloseDrawer open={open} setOpen={setOpen} />}
            </Box>

            <SubTitle
                text='Select the image (s) you want to translate in a valid format like PNG, JPG, etc'
                sx={{
                    mt: 4,
                    mb: 4
                }}
            />

            <SelectImageForm data={data} />
        </Container>
    )
}

export default SelectImage
