import React from 'react'

import { useLocation, Navigate, Outlet } from 'react-router-dom'

import { useStorage, storageDefaultStates } from '../utils'

const isAuthenticationExpired = ({ exp }) => {
    const currentDate = new Date()

    if (exp * 1000 < currentDate.getTime()) {
        return true // Expired
    }

    return false // Valid
}

const isAuthenticated = ({ ...authentication }) => {
    for (const [key, value] of Object.entries(
        storageDefaultStates[process.env.REACT_APP_STORAGE_AUTHENTICATION]
    )) {
        if (value !== authentication[key]) return true
    }

    return false
}

const RequireAuthentication = () => {
    // eslint-disable-next-line
    const [authenticationStorage, setAuthenticationStorage] = useStorage(
        process.env.REACT_APP_STORAGE_AUTHENTICATION
    )

    let location = useLocation()

    if (isAuthenticationExpired(authenticationStorage)) {
        return <Navigate to='/signin' state={{ from: location }} />
    }

    return <Outlet />
}

export { isAuthenticationExpired, isAuthenticated, RequireAuthentication }
