import React from 'react'

import { Typography } from '@mui/material'

const Title = ({ variant, component, id, sx, text }) => {
    return (
        <Typography
            variant={variant ? variant : 'h1'}
            component={component ? component : 'h2'}
            {...(id ? { id: id } : {})}
            sx={{
                fontSize: { xs: '2.50rem', sm: '3rem', md: '4.5rem' },
                ...sx
            }}
        >
            {text}
        </Typography>
    )
}

export default Title
