import React, { useState, Fragment, lazy, Suspense } from 'react'

import {
    AppBar,
    Toolbar,
    Stack,
    List,
    ListItemButton,
    ListItemText,
    Divider,
    useTheme,
    useMediaQuery,
    SwipeableDrawer,
    Typography
} from '@mui/material'

import { HashLink } from 'react-router-hash-link'

import { useStorage } from '../utils'

import { isAuthenticated, ContentDrawer } from '../components'

import Logo from './logo'

import { ReactComponent as MenuIcon } from '../assets/images/icons/menu.svg'

const SignIn = lazy(() => import('../pages/signIn'))

const SignUp = lazy(() => import('../pages/signUp'))

const toggleMenuItemsDrawer = (item, setMenuItemsDrawers) => open => {
    setMenuItemsDrawers(previous => ({
        ...previous,
        [item.id]: open
    }))
}

const MenuList = ({
    vertical,
    setMenuDrawer,
    menuItems,
    setMenuItemsDrawers
}) => {
    const theme = useTheme()

    return (
        <List
            component='nav'
            sx={{
                ...(vertical
                    ? {}
                    : {
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          '&:last-child > a:last-child': {
                              padding: '4px 8px',
                              marginLeft: '8px',
                              '& p': {
                                  fontWeight: '500'
                              }
                          }
                      })
            }}
            disablePadding
        >
            {menuItems.map(item => (
                <Fragment key={item.url}>
                    <ListItemButton
                        component={item.anchor ? HashLink : 'a'}
                        {...(item.anchor
                            ? {
                                  to: item.url,
                                  smooth: true,
                                  onClick: () => {
                                      if (item?.component)
                                          toggleMenuItemsDrawer(
                                              item,
                                              setMenuItemsDrawers
                                          )(true)

                                      if (vertical) setMenuDrawer(false)
                                  }
                              }
                            : { href: item.url })}
                        sx={{
                            '&:hover': vertical ? {} : theme.effect.hover
                        }}
                    >
                        <ListItemText
                            disableTypography
                            primary={
                                <Typography
                                    variant='body1'
                                    sx={{
                                        ...(item.bold
                                            ? { fontWeight: 500 }
                                            : {})
                                    }}
                                >
                                    {item.title}
                                </Typography>
                            }
                        >
                            {item.title}
                        </ListItemText>
                    </ListItemButton>

                    {item.divider && vertical && <Divider variant='middle' />}
                </Fragment>
            ))}
        </List>
    )
}

const Menu = () => {
    const theme = useTheme()

    const matchesUpLg = useMediaQuery(theme.breakpoints.up('lg'))

    const [menuDrawer, setMenuDrawer] = useState(false)

    // eslint-disable-next-line
    const [authenticationStorage, setAuthenticationStorage] = useStorage(
        process.env.REACT_APP_STORAGE_AUTHENTICATION
    )

    const [menuItemsDrawers, setMenuItemsDrawers] = useState({
        signIn: false,
        signUp: false
    })

    const menuItems = [
        { title: 'How it works', url: '/#howItWorks', anchor: true },
        { title: 'Contact', url: '/#contact', anchor: true, divider: true },
        ...(isAuthenticated(authenticationStorage)
            ? [{ title: 'Dashboard', url: '/dashboard', bold: true }]
            : [
                  {
                      title: 'Sign in',
                      url: '#signin',
                      anchor: true,
                      bold: true,
                      id: 'signIn',
                      component: SignIn
                  },
                  {
                      title: 'Sign up',
                      url: '#signup',
                      anchor: true,
                      bold: true,
                      id: 'signUp',
                      component: SignUp
                  }
              ])
    ]

    return (
        <>
            {menuItems
                .filter(item => {
                    return item?.component
                })
                .map((item, index) => (
                    <ContentDrawer
                        open={menuItemsDrawers[item.id]}
                        setOpen={toggleMenuItemsDrawer(
                            item,
                            setMenuItemsDrawers
                        )}
                        children={
                            <Suspense fallback={<></>}>
                                <item.component integrated />
                            </Suspense>
                        }
                        key={item.id + index}
                    />
                ))}

            {matchesUpLg ? (
                <MenuList
                    menuItems={menuItems}
                    setMenuItemsDrawers={setMenuItemsDrawers}
                />
            ) : (
                <>
                    <MenuIcon
                        onClick={() => {
                            setMenuDrawer(true)
                        }}
                        style={{
                            cursor: 'pointer'
                        }}
                    />

                    <SwipeableDrawer
                        anchor={'bottom'}
                        open={menuDrawer}
                        swipeAreaWidth={10}
                        PaperProps={{
                            sx: {
                                borderTopRightRadius: 5,
                                borderTopLeftRadius: 5
                            }
                        }}
                        onClose={() => setMenuDrawer(false)}
                        onOpen={() => setMenuDrawer(true)}
                    >
                        <MenuList
                            vertical={true}
                            setMenuDrawer={setMenuDrawer}
                            menuItems={menuItems}
                            setMenuItemsDrawers={setMenuItemsDrawers}
                        />
                    </SwipeableDrawer>
                </>
            )}
        </>
    )
}

const HeaderExternal = () => (
    <AppBar color='transparent' position='relative'>
        <Toolbar variant='dense' disableGutters>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                flexWrap='no-wrap'
                sx={{ width: '100%', minHeight: 100 }}
            >
                <Logo />

                <Menu />
            </Stack>
        </Toolbar>
    </AppBar>
)

export default HeaderExternal
