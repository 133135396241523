import React from 'react'

import { Box } from '@mui/material'

import { Loader } from './'

const GenericPageLoader = () => {
    return (
        <Box
            sx={{
                overflow: 'hidden',
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'border-box'
            }}
        >
            <Loader />
        </Box>
    )
}

export default GenericPageLoader
