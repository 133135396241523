import React, { useEffect } from 'react'

import {
    Container,
    Stack,
    TextField,
    Button,
    Box,
    Link,
    useTheme
} from '@mui/material'

import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import * as Yup from 'yup'

import { toast } from 'react-toastify'

import { request, formatErrors } from '../utils'

import { Title, SubTitle } from './'

const RecoverPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .required('Fill out this field')
        .trim()
        .email('Must be formatted as an email address')
})

const recoverPassword = async data => {
    const id = toast.loading('Recovering password')

    try {
        await request.post('/user/recover-password', {
            email: data.email.toLowerCase()
        })

        toast.update(id, {
            isLoading: false,
            render: 'New password sent to your email',
            type: toast.TYPE.SUCCESS,
            autoClose: 4000
        })
    } catch (error) {
        toast.update(id, {
            isLoading: false,
            render: error?.response
                ? formatErrors(error.response.data.detail).next()?.value
                : error?.message,
            type: toast.TYPE.ERROR,
            autoClose: 4000
        })
    }
}

const RecoverPasswordForm = ({ email }) => {
    const {
        register,
        handleSubmit,
        watch,
        trigger,
        formState: { errors, isSubmitting }
    } = useForm({
        mode: 'onSubmit',
        shouldFocusError: false,
        resolver: yupResolver(RecoverPasswordSchema),
        defaultValues: {
            email: email ? email : ''
        }
    })

    useEffect(() => {
        if (email) {
            trigger()
        }

        // eslint-disable-next-line
    }, [])

    return (
        <Stack
            direction='column'
            justifyContent='center'
            alignItems='flex-start'
            flexWrap='no-wrap'
            component='form'
            onSubmit={handleSubmit(recoverPassword)}
            sx={{ width: '100%', mt: '60px', gap: 8 }}
        >
            <TextField
                label='Email'
                value={watch('email')}
                variant='standard'
                InputLabelProps={{
                    required: false,
                    shrink: false,
                    ...(watch('email') ? { sx: { display: 'none' } } : {})
                }}
                fullWidth
                {...register('email')}
                error={!!errors.email}
                helperText={errors?.email?.message}
                sx={{ flex: 1 }}
            />

            <Button
                variant='contained'
                size='medium'
                type='submit'
                disabled={isSubmitting}
                fullWidth
                aria-label='recover-password'
            >
                Recover password
            </Button>
        </Stack>
    )
}

const RecoverPassword = ({ email, open, setOpen, integrated }) => {
    const theme = useTheme()

    return (
        <Container
            maxWidth='xs'
            sx={{
                minHeight: '60vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                ...(integrated ? { margin: '24px 0', minHeight: '65vh' } : {})
            }}
        >
            <Title
                text='Recover password'
                sx={{ fontSize: { xs: '2.25rem', sm: '3rem', md: '3rem' } }}
            />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    flexWrap: 'wrap',
                    gap: 1,
                    mt: 4
                }}
            >
                <SubTitle text='Got a new password? ' />

                <Link
                    href='#passwordRecovered'
                    rel='noreferrer'
                    target='_self'
                    onClick={() => {
                        setOpen(!open)
                    }}
                    sx={{
                        fontSize: '1.25rem',
                        fontWeight: 400,
                        color: theme.palette.secondary.main
                    }}
                >
                    Sign in
                </Link>
            </Box>

            <RecoverPasswordForm email={email} />
        </Container>
    )
}

export default RecoverPassword
