import React from 'react'

import { ReactComponent as Light } from '../assets/images/icons/light.svg'

import { ReactComponent as Expand } from '../assets/images/icons/expand.svg'

import { ReactComponent as Download } from '../assets/images/icons/download.svg'

import { ReactComponent as Close } from '../assets/images/icons/close.svg'

const ThemeDrawer = ({ themeDrawer, setThemeDrawer, sx }) => {
    return (
        <Light
            width='20'
            height='20'
            onClick={() => setThemeDrawer(!themeDrawer)}
            style={{ cursor: 'pointer', ...sx }}
        />
    )
}

const ExpandDrawer = ({ expandDrawer, setExpandDrawer, sx }) => {
    return (
        <Expand
            width='20'
            height='20'
            onClick={() => setExpandDrawer(!expandDrawer)}
            style={{ cursor: 'pointer', ...sx }}
        />
    )
}

const DownloadDrawer = ({ sx }) => {
    return (
        <Download width='20' height='20' style={{ cursor: 'pointer', ...sx }} />
    )
}

const CloseDrawer = ({ open, setOpen, sx }) => {
    return (
        <Close
            width='22'
            height='22'
            onClick={() => setOpen(false)}
            style={{ cursor: 'pointer', ...sx }}
        />
    )
}

export { ThemeDrawer, ExpandDrawer, DownloadDrawer, CloseDrawer }
